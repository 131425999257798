<template>
  <div>
<div class="header_box">
         <div class="header main">
      <div class="header_left">
        <img height="100%" src="@/assets/image/logo.png" alt="" />
      </div>
      <div class="header_right d-none d-sm-block">
        <el-menu router :default-active="$route.path"
         active-text-color="#fff"
         text-color="#fff"
         class="el-menu-demo"
          mode="horizontal">
          <el-menu-item index="/homepage">首页</el-menu-item>
          <el-menu-item index="/productLine">产品线</el-menu-item>
          <el-menu-item index="/application">应用软件</el-menu-item>
          <el-menu-item index="/industry">行业方案</el-menu-item>
          <el-menu-item index="/my">联系我们</el-menu-item>
        </el-menu>
      </div>
       <!-- <div class="header_top_lin"></div> -->
    </div>
</div>
  <!-- 弹框 -->
      <div class="header_right d-sm-none">
        <div class="dropdown">
          <div id="dropdownMenuButton1" data-bs-toggle="dropdown">
            <img src="@/assets/image/Banner/c6.png" alt="" />
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <!-- <li class="dropdown_li"></li> -->
            <el-menu
              router
              :default-active="$route.path"
              active-text-color="##0D36CE"
              text-color="##333333 "
              class="el-menu-demo"
            >
              <el-menu-item index="/homepage">首页</el-menu-item>
              <el-menu-item index="/productLine">产品线</el-menu-item>
              <el-menu-item index="/application">应用软件</el-menu-item>
              <el-menu-item index="/industry">行业方案</el-menu-item>
              <el-menu-item index="/my">联系我们</el-menu-item>
            </el-menu>
          </ul>
        </div>
      </div>

    <!-- 产品线start -->
    <ProDuct></ProDuct>
    <!-- 产品线end -->
    <!-- footer start -->
      <Footer></Footer>
    <!-- footer end -->
     <!-- <div class="footer d-sm-none">
      <div class="">
        <div class="main_one">
          <img width="40%" src="@/assets/image/logo.png" alt="" />
          <div class="footer_tit">爱四季</div>
        </div>
               <div class="footer-lin3 d-flex">
         <div>
             <div class="footer_min_tit add_footer_min_tit">
              业务经理
            </div>

            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer3.png" alt="" />
              </div>
              <div>邓经理:136 7004 6383</div>
            </div>
         </div>
            <div>
              <img src="@/assets/image/1655286203(1).jpg" alt="" />
            </div>
          </div>
        <div class="">
          <div class="footer-lin1">
            <div class="footer_min_tit">期待为您服务</div>
             <div class="lin_ft"></div>
<div class="d-flex">
          <div>
              <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer2.png" alt="" />
              </div>
              <div>近十年解决方案经验</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer2.png" alt="" />
              </div>
              <div>专注的领域经验</div>
            </div>
        </div>
           <div style=" margin-left: 10px;">
             <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer2.png" alt="" />
              </div>
              <div>近50人研发经验</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer2.png" alt="" />
              </div>
              <div>全球大客户服务经验</div>
            </div>
           </div>
</div>
            <div></div>
          </div>
          <div class="footer-lin2">
            <div class="footer_min_tit">联系我们</div>
             <div class="lin_ft"></div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer5.png" alt="" />
              </div>
              <div>电话：(+86)-xxxx-85582181</div>
            </div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/footer4.png" alt="" />
              </div>
              <div>邮件:support@i4season.com</div>
            </div>
            <div>
              <div class="mg_bom d-flex">
                <div class="mg_ri">
                  <img src="@/assets/image/footer1.png" alt="" />
                </div>
                <div>地址:深圳市南山区科苑路科兴科学园B区1A栋6楼</div>
              </div>
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;长沙市高新技术开发区麓谷企业广场F1栋24楼
              </div>
            </div>
          </div>
        </div>
        <div class="footer-ft">
         <div class="cen"> © 2017-2021 四季宏胜科技有限公司 </div>
          <div class="cen">版权所有 | ICP备2021006111号-2</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Footer from '../../components/footer.vue'
import ProDuct from '../../components/product.vue'
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Footer, ProDuct },
  data() {
    return {
      activeName: "index1"
    }
  }
}
</script>

<style lang="scss" scoped>
// header start
.el-menu{
  background: rgba($color: #000000, $alpha: .0);
}
/deep/.el-submenu__title:hover, .el-menu-item:focus, .el-menu-item:hover{
background-color: rgba($color: #000000, $alpha: .0) !important;
}
.el-menu.el-menu--horizontal {
    border:none;
}
.header_box{
  max-width: 1920px;
  margin: 0 auto;
  background: #4066F5;
  height: 80px;
}
.header{
  // background: #4066F5;
  display: flex;
}
.header_left{
  position: absolute;
  top: 16px;
}
.header_right {
  position: relative;
  left: 66%;
  top: 16px;
}
.header_top_lin{
  z-index: 99;
  width:calc(100vw);
  position: absolute;
  top: 100%;
  left: -30%;
  border-bottom: 1px solid rgba(252, 252, 252,0.3);
}
// header end


// footer start
.footer {
  // background: #4066f5;
  background: url('../../assets/image/her1.png') no-repeat center;
  color: #fff;
  font-family: PingFangSC-Medium;
  padding-bottom: 24px;
}
.main_one {
  height: 126px;
  display: flex;
  align-items: center;
}
.footer_main {
  display: flex;
  justify-content: space-between;
}
.footer_tit {
  color: #fff;
  font-weight: 200;
  font-family: FZLTXHK--GBK1-0;
  letter-spacing: 2px;
  font-size: 24px;
}
.footer_min_tit {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  // border-bottom:2px solid #fff ;
}
.lin_ft {
  width: 80px;
  border-bottom: 4px solid #fff;
  margin: 24px 0;
}
.mg_bom {
  margin-bottom: 12px;
}
.footer-ft {
  margin-top: 10px;
}
.mg_ri {
  margin-right: 8px;
  margin-top: -2px;
}
// footer end
// 手机
@media only screen and (max-width: 760px) {
  .header_box{
    height: 53px;
  }
    .header {
    width: calc(100vw);
  }
   .header_left {
     height: 28px;
    margin-left: 18px;
    margin-top: -2px;
  }
.header_right{
  left: 90%;
position: relative;
top: -40px;
}
  .dropdown{
    margin-bottom: 20px;
  }
  .dropdown-menu {
    width: calc(100vw);
    height: calc(100vh);
    background: #fff;
  }
// footer
  .footer{
    width: calc(100vw);
    padding: 0 20px;
    background: #4066F5;
  }
  .footer-lin3{
    justify-content: space-between;
  }
  .footer-lin1{
    margin-bottom:20px ;
    font-size: 12px;
  }
    .footer-lin2{
    font-size: 12px;
  }
   .footer-lin3{
    margin-bottom:20px ;
    font-size: 12px;
  }
  .lin_ft{
     border-bottom: 2px solid #fff;
     margin: 10px 0;
  }
  .footer-ft{
    padding:26px 0;
  }
  .cen{
    text-align: center;
  }
   .footer_min_tit{
    font-size: 16px;
  }
   .add_footer_min_tit{
    margin-bottom:16px ;
  }
  .footer-ft{
    font-size: 12px;
  }
    .footer_tit{
    font-size: 20px;
  }
}
</style>
